import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CameraIcon from '@material-ui/icons/PhotoCamera';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://dealproffsen.se/">
        Dealproffsen AB
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
  bar:{
    alignItems:"center"
  }
}));

const cards = [1, 2, 3, 4];

let obj = [
  {
    heading: "Dealproffsen Sverige",
    des:"",
    flag:"http://purecatamphetamine.github.io/country-flag-icons/3x2/SE.svg",
    visit:"Besök",
    url:"https://www.dealproffsen.se/"
  },{
    heading: "Dealproffsen Danmark",
    des:"",
    flag:"http://purecatamphetamine.github.io/country-flag-icons/3x2/DK.svg",
    visit:"Besøg",
    url:"https://dealproffsen.nu/dk/"
  },{
    heading: "Dealproffsen Finland",
    des:"",
    flag:"http://purecatamphetamine.github.io/country-flag-icons/3x2/FI.svg",
    visit:"Vierailla",
    url:"https://dealproffsen.nu/fi/"
  },{
    heading: "Dealproffsen Norge",
    flag:"http://purecatamphetamine.github.io/country-flag-icons/3x2/NO.svg",
    visit:"Besøk",
    url:"https://dealproffsen.no/"
  }
]

export default function Album() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
{/*       <AppBar color="transparent" position="relative" className={classes.bar}>
        <Toolbar >
          <Typography variant="h6" color="inherit">
            Dealproffsen 
          </Typography>
        </Toolbar>
      </AppBar> */}
      <main>
        {/* Hero unit */}
        <div className={classes.heroContent}>
          <Container maxWidth="sm">
            <Grid item  xs={12}>
            <img style={{width:"100%"}}  src="https://firebasestorage.googleapis.com/v0/b/dpapp-5bcf6.appspot.com/o/Dealproffsen%20logo.png?alt=media&token=a5beacfc-8457-413f-9229-7c99b6238f9b" />
            </Grid>
            <Typography variant="h7" align="center" color="textSecondary" paragraph>
            Dealproffsen AB is a Swedish e-commerce company with a wide range of household furniture, leisure and hobbies.
            We offer quality products at 25-75% lower prices than corresponding products in the scandinavian stores thanks to our strong network throughout Europe.
            </Typography>
            {/* <div className={classes.heroButtons}>
              <Grid container spacing={2} justifyContent="center">
                <Grid item>
                  <Button variant="contained" color="primary">
                    Main call to action
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant="outlined" color="primary">
                    Secondary action
                  </Button>
                </Grid>
              </Grid>
            </div> */}
          </Container>
        </div>
        <Container className={classes.cardGrid} maxWidth="md">
          {/* End hero unit */}
          <Grid container spacing={4}>
            {obj.map((card) => (
              <Grid item key={card} xs={6} sm={6} md={3}>
                <Card className={classes.card}>
                  <CardMedia
                    className={classes.cardMedia}
                    image={card.flag}
                    title="Image title"
                  />
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" component="h2">
                      {card.heading}
                    </Typography>
                    <Typography>
                      {card.des}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <a href={card.url}><Button size="small" color="primary">
                      {card.visit}
                    </Button></a>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </main>
      {/* Footer */}
      <footer className={classes.footer}>
        <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
        Deals for everyone
        </Typography>
        <Copyright />
      </footer>
      {/* End footer */}
    </React.Fragment>
  );
}